const config = {
  golemio: {
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im9sZGFtYWxlY0BnbWFpbC5jb20iLCJpZCI6NjMyLCJuYW1lIjpudWxsLCJzdXJuYW1lIjpudWxsLCJpYXQiOjE2MTIzNjg2MDQsImV4cCI6MTE2MTIzNjg2MDQsImlzcyI6ImdvbGVtaW8iLCJqdGkiOiI3NDUxMDNkZC1hMmY5LTQ1ZjItYjllMS02NDliMDQ3MzgwYzMifQ.3FtzJpOA_ibMo491fsnw3_Mc43T_lfYEq4wsxsnj8-Q'
  },
  mapbox: {
    style: 'mapbox://styles/oldamalec/ckkpnn5ks0zle17o0rghi6nxv',
    token: 'pk.eyJ1Ijoib2xkYW1hbGVjIiwiYSI6ImNra3BuNnhmMjAwNm4ycG82c3oxazhvdGgifQ.6P6boOuPDy3LSH-QckwdXg'
  }
}

export { config }

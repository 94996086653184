import axios from 'axios'
import { accessibility, GetParams } from '@/golemio/enum'
import { config } from '@/../config'

const Golemio = {
  API: 'https://api.golemio.cz/v2',
  RESOURCE: 'sortedwastestations',
  TOKEN: config.golemio.token,

  get (latLong = null) {
    const requestParams = new GetParams()
    requestParams.accessibility = accessibility.FREE.toString()
    // requestParams[params.limit] = 10
    requestParams.range = 1500
    requestParams.latlng = latLong ?? undefined

    return axios.get(this.API + '/' + this.RESOURCE + '?' + requestParams.serialize(), {
      headers: {
        'X-Access-Token': this.TOKEN
      }
    }).then(response => {
      return {
        type: 'FeatureCollection',
        // eslint-disable-next-line camelcase
        features: response.data.features.filter(feature => feature.properties.containers ? !feature.properties.containers.every(({ container_type }) => container_type !== null && (container_type.startsWith('240 L') || container_type.startsWith('120 L'))) : false)
      }
    })
  }
}

export { Golemio }

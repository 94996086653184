<template>
  <div id="app">
    <Mapbox
      :bins="shownBins"
      @map-moved="onMapMoved"
    />
    <TypeSelector
      :types-selected.sync="typesSelected"
    />
  </div>
</template>

<script>
import { Golemio } from '@/golemio/golemio'
import TypeSelector from '@/components/TypeSelector'
import { garbageType } from '@/golemio/enum'
import Mapbox from '@/components/Mapbox'

export default {
  name: 'App',
  components: {
    Mapbox,
    TypeSelector
  },
  data: () => ({
    allBins: null,
    typesSelected: [garbageType.PLASTICS, garbageType.PAPER]
  }),
  computed: {
    shownBins: function () {
      return this.allBins ? this.allBins.features.filter(b => {
        return this.typesSelected.every(e => (b.properties.containers.map(c => c.trash_type.id)).includes(e))
      }) : []
    }
  },
  methods: {
    onMapMoved: function (requested) {
      Golemio.get(requested.lat + ',' + requested.long).then(response => {
        this.allBins = response
      })
    }
  }
}
// TODO favicon
// TODO analytics
// TODO webmanifest icons
// TODO small infopage
// TODO allow reset to current location
// TODO allow reset north
// TODO autozoom based on available bins
// TODO API resource - public vs private bins
// TODO show bins in color by present bins
// TODO show filtered bins with low opacity
// TODO codestyle
// TODO lighthouse optimizations
// TODO ondragEnd ale mapa ještě jede
// TODO po načtění a probliknutí u Áďi nezůstanou vidět
</script>

<style>
body {
  margin: 0;
  font-family: 'Asap', sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#mapbox {
  width: 100vw;
  height: 100vh;
}

</style>

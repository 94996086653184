function GetParams () {
  return {
    latlng: null,
    range: null,
    districts: null,
    accessibility: null,
    limit: null,
    offset: null,
    onlyMonitored: null,
    serialize: function () {
      return Object.entries(this)
        .filter(([key, value]) => value !== null && typeof value !== 'function')
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    }
  }
}

const accessibility = {
  FREE: 1,
  RESIDENTS: 2,
  UNKNOWN: 3
}

const garbageType = {
  PLASTICS: 6,
  PAPER: 5,
  GLASS_TINTED: 1,
  GLASS_CLEAR: 7,
  BEVERAGE_CARTONS: 4,
  METALS: 3,
  ELECTRIC: 2,
  TEXTILES: 8
}

const typeLabel = {
  [garbageType.GLASS_TINTED]: 'Barevné sklo',
  [garbageType.ELECTRIC]: 'Elekro-odpad',
  [garbageType.METALS]: 'Kovy',
  [garbageType.BEVERAGE_CARTONS]: 'Nápojové kartony',
  [garbageType.PAPER]: 'Papír',
  [garbageType.PLASTICS]: 'Plasty',
  [garbageType.GLASS_CLEAR]: 'Čiré sklo',
  [garbageType.TEXTILES]: 'Textil'
}

const typeColor = {
  [garbageType.GLASS_TINTED]: '#3aab00',
  [garbageType.ELECTRIC]: '#a00000',
  [garbageType.METALS]: '#7a7a7a',
  [garbageType.BEVERAGE_CARTONS]: '#ff7500',
  [garbageType.PAPER]: '#0089ff',
  [garbageType.PLASTICS]: '#fff700',
  [garbageType.GLASS_CLEAR]: '#ddff8b',
  [garbageType.TEXTILES]: '#ffa200'
}

export { GetParams, accessibility, garbageType, typeLabel, typeColor }

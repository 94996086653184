<template>
  <div id="typeSelector">
    <div
      v-for="type of garbageType"
      :key="type"
      :style="
          'background-color: ' + typeColor[type] + ';' +
          'filter: ' + (typesSelected.includes(type) ? 'drop-shadow(2px 4px 6px black);' : 'opacity(0.3);')
        "
      :class="{
          'light': light.includes(type)
        }"
      @click="toggleType(type)"
    >
      <span>{{ typeLabel[type] }}</span>
    </div>
  </div>
</template>

<script>
import { garbageType, typeColor, typeLabel } from '@/golemio/enum'

export default {
  name: 'TypeSelector',
  props: {
    typesSelected: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    typeColor: typeColor,
    typeLabel: typeLabel,
    garbageType: garbageType
  }),
  computed: {
    light: () => [
      garbageType.PLASTICS,
      garbageType.GLASS_CLEAR,
      garbageType.TEXTILES
    ]
  },
  methods: {
    toggleType: function (type) {
      const index = this.typesSelected.indexOf(type)
      if (index === -1) {
        this.typesSelected.push(type)
      } else {
        this.typesSelected.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
#typeSelector {
  position: fixed;
  bottom: 0;
  padding-bottom: 8px;
  padding-top: 8px;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-between;
  align-items: center;
  z-index: 10;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.8);
}

#typeSelector div {
  border: 1px solid black;
  width: 11vw;
  height: 10vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
}

#typeSelector div.light {
  color: black;
}

#typeSelector div span {
  text-align: center;
  margin-left: 4px;
  margin-right: 4px;
  overflow: hidden;
}

@media (max-width: 600px) {
  #typeSelector {
    height: 20vh;
  }

  #typeSelector div {
    height: 9vh;
    width: 22vw;
  }
}
</style>

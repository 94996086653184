<template>
  <div id='mapbox'/>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import { config } from '@/../config'

export default {
  name: 'Mapbox',
  props: {
    bins: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    map: null,
    mapReady: false,
    userLong: 14.42,
    userLat: 50.08,
    binsSource: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    },
    binsLayer: {
      id: 'bins',
      type: 'symbol',
      source: 'bins',
      layout: {
        'icon-image': 'recycling-icon',
        'icon-size': 0.08,
        'icon-allow-overlap': true
      },
      paint: {
        'icon-opacity': 0.5
      }
    }
  }),
  watch: {
    bins: function () {
      this.mapReady && this.map.getSource('bins').setData({
        type: 'FeatureCollection',
        features: this.bins
      })
    }
  },
  created: function () {
    navigator.geolocation.getCurrentPosition(coords => {
      this.userLong = coords.coords.longitude
      this.userLat = coords.coords.latitude
      this.moveMap()
    }, error => {
      window.console.error(error)
    })
  },
  mounted: function () {
    mapboxgl.accessToken = config.mapbox.token

    this.map = new mapboxgl.Map({
      container: 'mapbox',
      style: config.mapbox.style + (process.env.NODE_ENV === 'development' ? '/draft' : ''),
      center: [this.userLong, this.userLat],
      zoom: 15
    })

    this.map.on('load', () => {
      this.map.addSource('bins', this.binsSource)
      this.mapReady = true
      this.map.addLayer(this.binsLayer)
      this.map.on('moveend', this.onMoveEnd)
      this.$emit('map-moved', {
        long: this.userLong,
        lat: this.userLat
      })
    })
  },
  methods: {
    moveMap: function () {
      if (this.map !== null) {
        this.map.jumpTo({ center: [this.userLong, this.userLat] })
        this.$emit('map-moved', {
          long: this.userLong,
          lat: this.userLat
        })
      }
    },
    onMoveEnd: function () {
      const center = this.map.getCenter()
      if ((Math.abs(this.userLong - center.lng) + Math.abs(this.userLat - center.lat)) > 0.013) {
        this.userLong = center.lng
        this.userLat = center.lat
        this.$emit('map-moved', {
          long: center.lng,
          lat: center.lat
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
